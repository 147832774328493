import { GetterTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';

import AdditionalServices from './types/additionalServices';

const getters: GetterTree<AdditionalServices, RootState> = {
  getServices (state) {
    return state.services;
  },
  getSyncedServices (state) {
    return state.lastSyncedServices;
  },
  getActiveServices (state) {
    return state.activeServices;
  },
  getProducts (state, getters, rootState) {
    const activeServices = getters.getActiveServices
    const platformTotals = rootState.cart.platformTotals
    const productsInCart = rootState.cart.cartItems

    if (!productsInCart || !platformTotals || !activeServices) {
      return null
    }

    const products = [];
    for (const product of productsInCart) {
      if (product.additional_services && product.additional_services.length > 0) {
        const syncedProduct = syncItemsOnProduct(product, platformTotals, activeServices);
        const newProduct = {
          id: syncedProduct.id,
          name: syncedProduct.name,
          items: syncedProduct.itemsWithServices
        };
        products.push(newProduct);
      }
    }
    return products;
  }
};

const syncItemsOnProduct = (product, platformTotals, activeServices) => {
  const matched = [];
  for (const item of product.items) {
    // for each product that is to be checked out
    const match = platformTotals.items.find(i => i.item_id === item.id);
    // and has matching items
    if (match) {
      const services = [];
      // for each matched item on the product create one instance of
      // service, so the customer can choose which items should have their own services
      for (const serviceMethod of product.additional_services) {
        // Attaching previosly selected option
        const matchedActiveService = activeServices.find(service => service.id === serviceMethod.id && service.item_id === match.item_id);
        const selected = !!matchedActiveService
        let selectedOption;
        if (serviceMethod.options && serviceMethod.options.length) {
          selectedOption = serviceMethod.options[0];
          if (matchedActiveService && matchedActiveService.selected_option) {
            selectedOption = matchedActiveService.selected_option;
          }
        }
        const itemService = {
          serviceKey: `${match.item_id}_${serviceMethod.id}`,
          item_id: match.item_id,
          selected_option: selectedOption,
          parentProduct: product.name,
          parentProductSku: product.sku,
          selected: selected,
          isActive: true,
          ...serviceMethod
        };
        services.push(itemService);
      }
      matched.push({ id: item.id, services });
    }
  }
  product.itemsWithServices = matched;
  return product;
};

export default getters;
