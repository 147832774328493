import * as types from './mutation-types';
import { CartService } from '@vue-storefront/core/data-resolver';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const actions = {
  // not used
  async fetchServices (context) {
    const { result } = await CartService.additionalServices();
    return 'test action';
  },
  // not used
  async pushAdditionalServices ({ rootState, commit, getters, rootGetters, dispatch }, { services }) {
    // todo
  },
  // this prepares data for checkboxes, so they appear
  // as active when user has selected them previously
  // and reopens the checkout again
  async syncActive ({ rootState, commit, getters, rootGetters, dispatch }, syncedProducts) {
    const services = []
    const productsInCart = rootGetters['cart/getCartItems']
    for (const product of syncedProducts) {
      if (product.selectedAdditionalServices) {
        for (const serviceMethod of product.selectedAdditionalServices) {
          const match = productsInCart.find((productInCart) => {
            return productInCart.sku === product.sku;
          });
          const itemService = {
            code: serviceMethod.code,
            name: serviceMethod.name,
            id: serviceMethod.id,
            price: serviceMethod.price,
            serviceKey: `${serviceMethod.item_id}_${serviceMethod.id}`,
            item_id: serviceMethod.item_id,
            parentProduct: match?.name,
            parentProductSku: product.sku,
            isActive: true,
            selected_option: serviceMethod.selected_option
          }
          services.push(itemService)
        }
      }
    }
    commit(types.SET_ACTIVE, services)
  },
  // this is being called anytime user changes
  // the services
  setActive ({ rootState, commit, getters, rootGetters, dispatch }, services) {
    // propagate the changes to server
    EventBus.$emit('checkout-after-additionalServicesChanged', services)
    commit(types.SET_ACTIVE, services)
  }
};

export default actions;
