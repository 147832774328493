import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import AdditionalServices from './types/additionalServices'

const mutations: MutationTree<AdditionalServices> = {
  [types.ADD] (state, { val }) {
    state.services = { test: 'test' }
  },
  [types.SYNC_ACTIVE] (state, services) {
    state.lastSyncedServices = services
  },
  [types.SET_ACTIVE] (state, services) {
    state.activeServices = services
  }
}

export default mutations
